@import './variables';

.title {
  margin: 32px 0 28px;
}

.secondTitle {
  margin: 72px 0 32px;
}

.games {
  display: grid;
  align-content: flex-start;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, 355px);
  justify-content: center;
  gap: 32px 20px;
  margin-bottom: 32px;
}
